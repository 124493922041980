<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex" style="justify-content: center">
            <div class="image-input image-input-outline" :style="{ backgroundImage: `url(${photo})` }">
              <div class="image-input-wrapper text-left"></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input type="file" accept=".png, .jpg, .jpeg" @change="onFileChange($event)" />
              </label>
            </div>
            <!-- <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center mt-8">
              <div class="symbol-label mx-30">
                <div class="image-input image-input-outline mx-40" id="kt_profile_avatar">
                  <div class="image-input-wrapper"></div>
                  <label
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input
                      type="file"
                      name="profile_avatar"
                      accept="image/png, image/gif, image/jpeg"
                      @change="onFileChange($event)"
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel"
                    data-toggle="tooltip"
                    title="Cancel avatar"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
              </div>
            </div> -->
          </div>
          <!--end::User-->
          <div class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary py-9 text-center">
            {{ getFullName }}
          </div>
          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <div class="navi-item mb-2">
              <a
                class="navi-link active py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Personal Information</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Change Password</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab title="Personal Information">
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>

        <b-tab title="Change Password">
          <KTChangePassword></KTChangePassword>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { base_url } from '../../const'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import KTPersonalInformation from '@/view/pages/profile/profile-comp/PersonalInformation'

import KTChangePassword from '@/view/pages/profile/profile-comp/ChangePassword'

export default {
  name: 'profile',
  components: {
    KTPersonalInformation,

    KTChangePassword,
  },
  data() {
    return {
      userInfo: {},
      tabIndex: 0,
      default_photo: 'media/users/blank.png',
      current_photo: null,
      mainProps: { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' },
    }
  },
  mounted() {
    console.log(`this.currentUser.photoUrl`, this.currentUser.photoUrl)
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Profile' }])
    this.default_photo = `${base_url}/uploads/default/${this.currentUser.photoUrl}`
    console.log(`this.default_photo`, this.default_photo)
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target
      if (!event.target.classList.contains('navi-link')) {
        target = event.target.closest('.navi-link')
      }

      const tab = target.closest('[role="tablist"]')
      const links = tab.querySelectorAll('.navi-link')
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute('data-tab'))

      // set current active tab
      target.classList.add('active')
    },
    onFileChange(e) {
      const file = e.target.files[0]
      console.log(`e`, e)
      // const name = e.target.files[0].name
      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          console.log(`event`, event)
          // const uri = event.target.result
          this.current_photo = event.target.result
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
  },
  computed: {
    currentUser() {
      var currentUser = localStorage.getItem('scooble_user')

      return JSON.parse(currentUser)
    },

    getFullName() {
      return this.currentUser.firstname + ' ' + this.currentUser.lastname
    },
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo
    },
  },
}
</script>
