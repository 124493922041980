<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Form-->

    <!--begin::Body-->
    <b-card footer-tag="footer" footer-class="text-right">
      <b-row>
        <b-col cols="12" lg="6">
          <h5 class="font-weight-bolder mb-9">Personal Info</h5>

          <b-form>
            <b-form-group>
              <div class="d-flex justify-content-between" v-if="list.firstname && list.lastname">
                <b-form-input class="mr-1" placeholder="First Name" v-model="list.firstname"></b-form-input>
                <b-form-input class="ml-1" placeholder="Last Name" v-model="list.lastname"></b-form-input>
              </div>
            </b-form-group>
            <!-- <b-form-group>
              <b-form-input :value="currentUser.company.name" placeholder="Company Name"></b-form-input>
            </b-form-group> -->
            <b-form-group>
              <b-form-input v-model="list.email"></b-form-input>
            </b-form-group>
          </b-form>
          <!-- <h5 class="font-weight-bolder mb-9 mt-11">Links</h5>
          <b-form-group>
            <b-form-input
              :value="currentUser.company.disclosureLink"
              placeholder="Link to Legal Disclosure"
            ></b-form-input>
            <span class="form-text text-muted">Link to Legal Disclosure</span>
          </b-form-group> -->
          <!-- <b-form-group>
            <b-form-input :value="currentUser.company.policyLink" placeholder="Link to other Policies"></b-form-input>
            <span class="form-text text-muted">Link to other Policies</span>
          </b-form-group>
          <b-form-group>
            <b-form-input
              :value="currentUser.company.termsLink"
              placeholder="Link to Terms & Conditions"
            ></b-form-input>
            <span class="form-text text-muted">Link to Terms & Conditions</span>
          </b-form-group> -->
        </b-col>

        <b-col cols="12" lg="6">
          <div class="form-group">
            <h5 class="font-weight-bolder mb-9">Account Info</h5>
            <!-- <select class="form-control" id="exampleFormControlSelect1">
              <option v-for="(r, i) in role" v-bind:key="i">{{ r }}</option>
            </select> -->
            <select
              :disabled="list.role.name !== 'superadmin'"
              class="form-control"
              id="exampleFormControlSelect1"
              @change="setRole($event)"
            >
              <option v-for="(r, i) in role" v-bind:key="i" :value="r._id" :selected="list.role._id == r._id">
                {{ r.name }}
              </option>
            </select>
          </div>

          <h5 class="font-weight-bolder mt-11 mb-9">Address</h5>
          <p style="font-weight: 400; margin: 8px">Your dont have Addresses</p>

          <b-form-group v-if="list.addresses[0]">
            <div class="d-flex">
              <b-form-input placeholder="Street" v-model="list.addresses[0].street" class="mr-2"></b-form-input>
              <b-form-input v-model="list.addresses[0].houseNumber" placeholder="House No" class="col-3"></b-form-input>
            </div>
          </b-form-group>
          <b-form-group v-if="list.addresses[0]">
            <div class="d-flex">
              <b-form-input v-model="list.addresses[0].zipCode" placeholder="ZIP Code" class="col-3"></b-form-input>
              <b-form-input v-model="list.addresses[0].city" placeholder="City" class="ml-2"></b-form-input>
            </div>
          </b-form-group>
        </b-col>

        <!--end::Body-->
      </b-row>

      <template #footer>
        <button type="reset" class="btn btn-primary mr-2" ref="kt_save_changes" @click="changedDetails">
          Save Changes
        </button>
      </template>
    </b-card>
    <!--end::Form-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import UsersServices from '../../../../core/services/users'
import AddressServices from '../../../../core/services/users/address.service'
import Swal from 'sweetalert2'

export default {
  name: 'PersonalInformation',
  data() {
    return {
      role: [],
      default_photo: 'media/users/blank.png',
      current_photo: null,
      list: {},
      paramUserId: '',
      loginUserRole: '',
      selectedRole: '',
      userEmail: '',
    }
  },
  mounted() {
    this.roles()
    this.userdetail()
    this.current_photo = this.currentUser.photoUrl
  },
  methods: {
    userdetail() {
      ApiService.setHeader()
      ApiService.get('users/' + this.currentUser._id).then(({ data }) => {
        console.log(`dataaaa`, data)
        this.userEmail = data.email
        this.list = data
      })
    },
    roles() {
      ApiService.setHeader()
      ApiService.get('users/roles').then(({ data }) => {
        data.forEach(element => {
          this.role.push({
            _id: element._id,
            name: element.name,
          })
        })
      })
    },
    setRole(event) {
      this.selectedRole = event.target.value
    },
    async changedDetails() {
      let newProfileDetail = {
        firstname: this.list.firstname || undefined,
        lastname: this.list.lastname || undefined,
        email: undefined,
        photoUrl: undefined,
        thumbPhotoUrl: undefined,
      }
      let newAddressDetail = {
        street: this.list.addresses[0].street || '',
        houseNumber: this.list.addresses[0].houseNumber || '',
        zipCode: this.list.addresses[0].zipCode || '',
        city: this.list.addresses[0].city || '',
      }
      let changeRole = false
      let changeProfile = false
      let changeAddresses = false

      if (this.list.email !== this.userEmail && typeof this.list.email !== 'undefined' && this.list.email !== '') {
        newProfileDetail.email = this.list.email
      }

      if (this.selectedRole) {
        // changed role
        ApiService.setHeader()
        ApiService.put('/users/roles/user-role-change', {
          userId: this.list._id,
          roleId: this.selectedRole,
          superAdminId: this.list._id,
        })
          .then(() => {
            console.log('Success.')
          })
          .catch(res => {
            console.log(`change role error`, res)
            changeRole = true
          })
      }

      // changed profile
      const changedUserProfile = await UsersServices.update({
        profile: newProfileDetail,
        _id: this.list._id,
        deviceLocale: 'de',
      })

      console.log(`changedUserProfile`, changedUserProfile)

      // changed addresses
      if (this.list.addresses.length === 0) {
        const createdAddress = await AddressServices.create({ newAddressDetail })
        console.log(`createdAddress`, createdAddress)
      } else {
        const updateAddress = await AddressServices.update(newAddressDetail, this.list.addresses[0]._id)
        console.log(`updateAddress`, updateAddress)
      }
      if (changeRole || changeProfile || changeAddresses) {
        return Swal.fire('unexpected error', '', 'error')
      }

      Swal.fire('changes updated', '', 'success')
    },
  },
  computed: {
    currentUser() {
      var currentUser = localStorage.getItem('scooble_user')
      console.log(`JSON.parse(currentUser)`, JSON.parse(currentUser))

      return JSON.parse(currentUser)
    },
  },
}
</script>
