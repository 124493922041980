import ApiService from '../api.service'
const endPoint = '/users/addresses'

const { put, post } = ApiService

class AddressServices {
  async create(data) {
    return await post(endPoint, data)
  }

  async update(data, id) {
    return await put(`${endPoint}/${id}`, data)
  }
}
export default new AddressServices()
